<template>
  <div class="odds-checker-wrapper">
    <div class="odds-checker-table-wrapper player-props">
      <div class="odds-checker-header">
        <div class="odds-checker-header-label">
          Player props
        </div>
        <div class="odds-checker-header-columns-search-wrapper">
          <Button
            icon="three-columns"
            @click="toggleColumnsSidebar"
          >
            Columns
          </Button>
          <div class="odds-checker-header-search">
            <TextInput
              :model-value="searchValue"
              :placeholder="'Search events'"
              :icon="'search'"
              @update:modelValue="onSearch"
            />
          </div>
        </div>
      </div>
      <div class="odds-checker-filters">
        <!-- sport filter -->
        <div
          class="odds-checker-filter-wrapper sport"
          @click="() => { sportFilterToggled = !sportFilterToggled }"
          ref="sportDropdownRef"
        >
          <div class="selected-filter-value">
            <span>{{ selectedSport?.sportName }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="odds-checker-filter-dropdown"
            v-if="sportFilterToggled"
          >
            <div
              class="odds-checker-filter-option"
              v-for="sport in sports"
              :key="sport.sportId"
              @click="selectSport(sport)"
            >
              <RadioInput
                :checked="sport.sportId === selectedSportId"
              />
              {{ sport.sportName }}
            </div>
          </div>
        </div>
        <!-- competition filter -->
        <div
          class="odds-checker-filter-wrapper competition"
          @click="() => { competitionFilterToggled = !competitionFilterToggled }"
          ref="competitionDropdownRef"
        >
          <div class="selected-filter-value">
            <span>{{ selectedCompetitionsLabel }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="odds-checker-filter-dropdown"
            v-if="competitionFilterToggled"
          >
            <div
              class="odds-checker-filter-option"
              v-for="competition in competitions"
              :key="competition.competitionId"
              @click="toggleCompetition(competition)"
            >
              <Checkbox
                :model-value="isCompetitionSelected(competition.competitionId)"
                @update:modelValue="toggleCompetition(competition)"
              />
              <span>{{ competition.competitionName }}</span>
            </div>
          </div>
        </div>
        <!-- market filter -->
        <div
          class="odds-checker-filter-wrapper sport market"
          @click="() => { marketFilterToggled = !marketFilterToggled }"
          ref="marketDropdownRef"
        >
          <div class="selected-filter-value">
            <span>Market: {{ selectedMarket?.label }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="odds-checker-filter-dropdown market"
            v-if="marketFilterToggled"
          >
            <div
              class="odds-checker-filter-option"
              v-for="market in filterMarketOptions"
              :key="market.id"
              @click="selectMarket(market)"
            >
              <RadioInput
                :checked="market.id === selectedMarket.id"
              />
              {{ market.label }}
            </div>
          </div>
        </div>
        <!-- state filter -->
        <div
          class="odds-checker-filter-wrapper state"
          @click="() => { stateFilterToggled = !stateFilterToggled }"
          ref="stateDropdownRef"
        >
          <div class="selected-filter-value">
            <span>{{ selectedStateLabel }}</span>
            <Icon
              class="filter-icon"
              :name="'chevron-selector'"
            />
          </div>
          <div
            class="odds-checker-filter-dropdown"
            v-if="stateFilterToggled"
          >
            <div
              class="odds-checker-filter-option"
              @click="toggleState('live')"
            >
              <Checkbox
                :model-value="isStateSelected('live')"
                @update:modelValue="toggleState('live')"
              />
              <span>Live</span>
            </div>
            <div
              class="odds-checker-filter-option"
              @click="toggleState('prematch')"
            >
              <Checkbox
                :model-value="isStateSelected('prematch')"
                @update:modelValue="toggleState('prematch')"
              />
              <span>Prematch</span>
            </div>
          </div>
        </div>
      </div>
      <OddsCheckerTable
        v-if="oddsCheckerPlayers.length && !oddsCheckerEventsLoading"
        :events="oddsCheckerPlayers"
        :loading="oddsCheckerEventsLoading"
        :table-columns="displayedTableColumns || []"
        :is-player-props="true"
        :selected-market="selectedMarket"
        :all-table-columns="tableColumns"
      />
      <div
        class="empty-state-wrapper"
        v-if="!oddsCheckerEventsLoading && !oddsCheckerPlayers.length && selectedSport"
      >
        <EmptyState
          class="empty-state"
          :message="'No events at the moment'"
        />
      </div>
      <Footer v-if="!oddsCheckerEventsLoading && selectedSport" />
      <div
        class="loading-spinner-wrapper"
        v-if="oddsCheckerEventsLoading"
      >
        <Spinner
          class="table-spinner"
        />
      </div>
    </div>
    <div
      :class="['bookmakers-sidebar', columnsSidebarActive && 'active']"
    >
      <div class="sidebar-header">
        <span>Columns</span>
        <Icon
          name="close"
          @click="() => columnsSidebarActive = false"
        />
      </div>
      <div class="sidebar-content">
        <bookmakerColumnsSidebar
          v-if="columnsSidebarActive"
          :columns="tableColumns"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import {
  debounce, find, includes, filter, orderBy,
  cloneDeep, capitalize, each, groupBy, concat, map,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import RadioInput from '@/components/common/RadioInput';
import Checkbox from '@/components/common/Checkbox';
import EmptyState from '@/components/common/EmptyState';
import Spinner from '@/components/common/Spinner';
import Footer from '@/components/common/Footer';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import OddsCheckerTable from './table-configuration/OddsCheckerTable';
import {
  generateInfoPlayerColumns, generateInternalEventColumns, generateLSportsEventColumns,
  generateInternalClientsEventColumns, generatePlayerFilterMarkets,
} from './table-configuration/odds-checker-table-helper';
import BookmakerColumnsSidebar from './BookmakerColumnsSidebar';

export default {
  components: {
    TextInput,
    Icon,
    RadioInput,
    Checkbox,
    OddsCheckerTable,
    EmptyState,
    Spinner,
    Footer,
    Button,
    BookmakerColumnsSidebar,
  },
  setup() {
    const searchValue = ref('');
    const sportFilterToggled = ref(false);
    const competitionFilterToggled = ref(false);
    const marketFilterToggled = ref(false);
    const stateFilterToggled = ref(false);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { isSuperAdmin, SUPER_ADMIN_CLIENT } = getSuperAdminData();
    const selectedOperator = computed(() => route.query.client);
    const superAdminEnabled = computed(() => isSuperAdmin && selectedOperator.value === SUPER_ADMIN_CLIENT);
    const operator = computed(() => store.getters.operator);

    const generateNonSuperAdminColumns = () => concat(generateInfoPlayerColumns(), generateInternalEventColumns(selectedOperator.value || operator.value), generateLSportsEventColumns());
    const generateSuperAdminColumns = () => concat(generateInfoPlayerColumns(), generateInternalClientsEventColumns(), generateLSportsEventColumns());

    const userConfigurationBookmakers = computed(() => store.getters.userConfigurationBookmakers);

    const tableColumns = computed(() => {
      let mappedColumns = [];
      if (superAdminEnabled.value) {
        mappedColumns = generateSuperAdminColumns();
      } else {
        mappedColumns = generateNonSuperAdminColumns();
      }
      mappedColumns = map(mappedColumns, (column) => {
        const userConfigurationColumn = find(userConfigurationBookmakers.value,
          (userColumn) => userColumn.source === column.id
            && ((userColumn.type === 'bookmaker-operator' && userColumn.operatorId === column.operatorId) || userColumn.type === 'bookmaker-generic')
            && userColumn.gateway === column.gateway);
        if (!userConfigurationColumn) return column;
        return {
          ...column,
          isActive: userConfigurationColumn.isActive,
          position: userConfigurationColumn.position,
        };
      });
      return orderBy(mappedColumns, 'position');
    });

    const displayedTableColumns = computed(() => orderBy(filter(tableColumns.value,
      (column) => column.columnType !== 'market' || column.alwaysDisplay || column.isActive)),
    'position');

    const sports = computed(() => store.getters.oddsCheckerSports);
    const competitions = computed(() => store.getters.oddsCheckerCompetitions);
    const oddsCheckerPlayers = computed(() => {
      let allPlayers = [];
      const filteredEvents = filter(store.getters.oddsCheckerEvents, (event) => event.matchState !== 'FINISHED');
      each(filteredEvents, (event) => {
        const eventMarkets = store.getters.oddsCheckerMarketsByEventId(event.eventId);
        const groupedPlayers = groupBy(eventMarkets, 'marketSummary.playerId');
        const eventPlayers = [];
        each(groupedPlayers, (markets) => {
          const playerNameSplit = markets[0]?.marketSummary?.playerName?.split(', ');
          if (!playerNameSplit) return;
          const playerName = `${playerNameSplit[1]} ${playerNameSplit[0]}`;
          eventPlayers.push({
            playerName,
            eventId: event.eventId,
            eventName: event.eventName,
            competitors: event.competitors,
            startsAt: event.startsAt,
            isUsaView: event.isUsaView,
            matchState: event.matchState,
            markets,
          });
        });
        allPlayers = concat(allPlayers, orderBy(eventPlayers, ['markets.nodes[0].marketType.params.TEAM', 'playerName'], ['desc', 'asc']));
      });
      return allPlayers;
    });
    const oddsCheckerEventsLoading = computed(() => store.getters.oddsCheckerEventsLoading);

    const selectedSportId = computed(() => route.query.sport ?? '');
    const selectedCompetitionsIds = computed(() => route.query.competitions ?? '');
    const selectedStates = computed(() => route.query.state ?? '');

    const selectedSport = computed(() => find(sports.value, (sport) => sport.sportId === selectedSportId.value) || sports.value[0]);

    const filterMarketOptions = ref([]);

    const selectMarket = (market) => {
      store.dispatch('setSelectedOddsCheckerMarket', market);
    };

    const setFilterMarketOptions = (sportId) => {
      filterMarketOptions.value = generatePlayerFilterMarkets()?.[sportId || selectedSportId.value] || [];
      selectMarket(filterMarketOptions.value[0]);
    };

    setFilterMarketOptions();

    const selectedMarket = computed(() => store.getters.selectedOddsCheckerMarket);

    const selectedCompetitionsLabel = computed(() => {
      const competitionIds = selectedCompetitionsIds.value ? selectedCompetitionsIds.value?.split(',') : [];
      if (competitionIds.length > 1) return `Competitions: +${competitionIds.length}`;
      if (competitionIds.length === 1) return `Competition: ${find(competitions.value, (competition) => competition.competitionId === competitionIds[0])?.competitionName || ''}`;
      return 'Competition';
    });

    const selectedStateLabel = computed(() => {
      const states = selectedStates.value ? selectedStates.value?.split(',') : [];
      if (states.length > 1) return `State: +${states.length}`;
      if (states.length === 1) return `State: ${capitalize(states[0])}`;
      return 'State';
    });

    const updateQueryParams = (selection, deleteQuery, type) => {
      const query = {
        ...route.query,
        [selection.queryField]: selection.newValue,
      };
      if (deleteQuery && type === 'competitions') delete query.competitions;
      if (deleteQuery && type === 'states') delete query.state;
      router.push({
        name: route.name,
        query,
      });
    };

    const loadEvents = ({ competitionIds, stateIds, previousSelectedMarket }) => {
      const competitionsParams = competitionIds || (selectedCompetitionsIds.value ? selectedCompetitionsIds.value.split(',') : false);
      const statesParams = stateIds || (selectedStates.value ? selectedStates.value.toUpperCase().split(',') : []);
      store.dispatch('loadOddsCheckerEvents', {
        sportId: selectedSportId.value,
        competitionIds: competitionsParams,
        stateIds: statesParams,
        searchQuery: searchValue.value,
        routeName: 'playerProps',
        previousSelectedMarket,
      });
    };

    const selectSport = (sport, dontLoadFilters) => {
      updateQueryParams({ queryField: 'sport', newValue: sport.sportId }, true, 'competitions'); // 2nd param for deleting competitions
      if (dontLoadFilters) return;
      const statesQuery = selectedStates.value ? selectedStates.value.toUpperCase().split(',') : [];
      store.dispatch('loadFiltersMeta', {
        sportId: sport.sportId, competitionsOnly: true, statesQuery, routeName: 'playerProps',
      });
      setFilterMarketOptions(sport.sportId);
    };

    const toggleCompetition = (competition) => {
      let competitionIds = selectedCompetitionsIds.value ? cloneDeep(selectedCompetitionsIds.value).split(',') : [];
      if (includes(competitionIds, competition.competitionId)) {
        competitionIds = filter(competitionIds, (id) => id !== competition.competitionId);
      } else {
        competitionIds.push(competition.competitionId);
      }
      const deleteCompetitionsQuery = !competitionIds.length;
      updateQueryParams({ queryField: 'competitions', newValue: competitionIds.join(',') }, deleteCompetitionsQuery, 'competitions');
      loadEvents({ competitionIds });
    };

    const toggleState = (stateId) => {
      let states = selectedStates.value ? cloneDeep(selectedStates.value).split(',') : [];
      if (includes(states, stateId)) {
        states = filter(states, (id) => id !== stateId);
      } else {
        states.push(stateId);
      }
      const deleteStatesQuery = !states.length;
      updateQueryParams({ queryField: 'state', newValue: states.join(',') }, deleteStatesQuery, 'states');
      const stateFilters = states.length ? states.join(',').toUpperCase().split(',') : [];
      loadEvents({ stateIds: stateFilters });
    };

    const isCompetitionSelected = (competitionId) => {
      const competitionIds = selectedCompetitionsIds.value?.split(',');
      return includes(competitionIds, competitionId);
    };

    const isStateSelected = (stateId) => {
      const states = selectedStates.value?.split(',');
      return includes(states, stateId);
    };

    const debounceLoadEvents = debounce(() => {
      loadEvents({});
    }, 500);

    const onSearch = (value) => {
      searchValue.value = value;
      debounceLoadEvents();
    };

    const columnsSidebarActive = ref(false);

    const toggleColumnsSidebar = () => {
      columnsSidebarActive.value = !columnsSidebarActive.value;
    };

    const sportDropdownRef = ref('');
    const competitionDropdownRef = ref('');
    const marketDropdownRef = ref('');
    const stateDropdownRef = ref('');

    onClickOutside(sportDropdownRef, () => {
      sportFilterToggled.value = false;
    });

    onClickOutside(competitionDropdownRef, () => {
      competitionFilterToggled.value = false;
    });

    onClickOutside(marketDropdownRef, () => {
      marketFilterToggled.value = false;
    });

    onClickOutside(stateDropdownRef, () => {
      stateFilterToggled.value = false;
    });

    onMounted(() => {
      if (!selectedSportId.value) selectSport({ sportId: '841e188b-0dcf-4f5f-974f-aa52c8cec95b' }, true); // Select Am. Football as default, 2nd params for not loading filters
      const competitionsQuery = selectedCompetitionsIds.value ? selectedCompetitionsIds.value.split(',') : false;
      const statesQuery = selectedStates.value ? selectedStates.value.toUpperCase().split(',') : [];
      store.dispatch('loadFiltersMeta', {
        sportId: selectedSportId.value,
        competitionsQuery,
        statesQuery,
        routeName: 'playerProps',
      });
    });
    onBeforeUnmount(() => {
      store.dispatch('unloadOddsCheckerEvents');
    });

    watch(() => store.getters.rowsPerPage, (newValues, oldValues) => {
      if (newValues !== oldValues) loadEvents({});
    });

    watch(() => store.getters.currentPage, (newValues, oldValues) => {
      if (newValues !== oldValues) loadEvents({});
    });

    watch(() => selectedMarket.value, (newValues, oldValues) => {
      if (newValues?.id !== oldValues?.id) loadEvents({ previousSelectedMarket: oldValues });
    });

    return {
      searchValue,
      onSearch,
      sportFilterToggled,
      sports,
      competitions,
      competitionFilterToggled,
      stateFilterToggled,
      selectedSportId,
      selectSport,
      selectedSport,
      sportDropdownRef,
      competitionDropdownRef,
      stateDropdownRef,
      selectedCompetitionsLabel,
      selectedStateLabel,
      toggleCompetition,
      toggleState,
      isCompetitionSelected,
      isStateSelected,
      oddsCheckerPlayers,
      oddsCheckerEventsLoading,
      tableColumns,
      filterMarketOptions,
      selectedMarket,
      marketDropdownRef,
      marketFilterToggled,
      selectMarket,
      toggleColumnsSidebar,
      columnsSidebarActive,
      userConfigurationBookmakers,
      displayedTableColumns,
    };
  },
};
</script>

<style lang="scss">
  .odds-checker-wrapper {
    width: 100%;
    height: calc(100% - 69px);
    position: relative;
    overflow: hidden;

    .odds-checker-table-wrapper {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;

      .odds-checker-header {
        height: 48px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        .odds-checker-header-label {
          height: 32px;
          line-height: 32px;
          color: #191414;
          font-weight: 600;
          font-size: 20px;
          font-family: 'Poppins';
        }

        .odds-checker-header-columns-search-wrapper {
          height: 32px;
          display: flex;
          align-items: center;
          gap: 8px;

          .odds-checker-header-search {
            height: 32px;
            width: 240px;

            .text-field {
              height: 32px;

              .text-field-clear {
                width: 32px;
                height: 32px;
              }
            }
          }
        }
      }

      .odds-checker-filters {
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;

        .odds-checker-filter-wrapper {
          width: 159px;
          height: 32px;
          border-radius: 4px;
          border: 1px solid #F0F0F0;
          cursor: pointer;
          position: relative;

          &.market {
            width: 200px
          }

          .selected-filter-value {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 8px;

            span {
              max-width: 85%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .icon {
              width: 16px;
              height: 16px;
            }
          }

          .odds-checker-filter-dropdown {
            width: 180px;
            min-height: 32px;
            position: absolute;
            top: 33px;
            left: 0;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #F0F0F0;
            box-shadow: 0px 2px 4px 0px #19141414;
            z-index: 999;

            &.market {
              width: 224px;
            }

            .odds-checker-filter-option {
              height: 32px;
              width: 100%;
              cursor: pointer;
              padding:  0 8px;
              display: flex;
              align-items: center;

              &:hover {
                background-color: rgba(0,0,0, 0.03);
              }

              .radio-input, .checkbox {
                margin-right: 4px;
              }

              span {
                max-width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }

        }
      }

      .loading-spinner-wrapper {
        height: 60%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .empty-state-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 232px);
      width: 100%;

      .empty-state {
        width: 400px;
      }
    }

    .bookmakers-sidebar {
      width: 320px;
      height: 100%;
      position: absolute;
      top: 0;
      right: -320px;
      background-color: #fff;
      transition: right .5s;
      z-index: 1000;

      &.active {
        right: 0;
      }
      .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        padding: 16px;
        border-bottom: 1px solid $gray400;
        border-left: 1px solid $gray400;
        background-color: $white;
        font-weight: 600;
        font-size: 16px;
        .icon {
          width: 8px;
          height: 8px;
          cursor: pointer;
        }
      }
      .sidebar-content {
        height: calc(100% - 55px);
        border-left: 1px solid $gray400;
      }
    }
  }
</style>
